import React, { useState, useEffect, useLayoutEffect } from 'react'

import { sub, format, parseISO, isBefore } from 'date-fns'
import axios from 'axios'
import QRCode from 'react-qr-code'
import { useTheme } from 'styled-components'
import useWidth from 'src/hooks/useWidth'
import Layout from 'src/components/Layout'
import PageTitle from 'src/components/PageTitle'
import AreaChart from 'src/components/Charts/AreaChart'
import OrangeIcon from 'src/components/Icon/OrangeDsIcon'
import Tags from 'src/components/Tags'
import Columnists from 'src/components/Columnists'
import FloatingSocialMedia from 'src/components/FloatingSocialMedia'
import FloatingSocialMediaSharer from 'src/components/FloatingSocialMedia/assets/data/FloatingSocialMediaSharer.json'
import authorsJSON from 'src/assets/data/author.json'
import { CMS_API_QUOTATIONS } from 'src/config/api/Urls'
import { convertFloatToCurrencyString } from 'src/shared/helpers'
import pt from 'date-fns/locale/pt-BR'
import quotationData from 'src/assets/data/quotation-analysis.json'
import Image from 'src/components/ContentBlocks/Image'
import Text from 'src/components/ContentBlocks/Text'
import FullScreenModal from 'src/components/FullScreenModal'
import SpotlightNewsSmall from 'src/components/SpotlightNewsSmall'
import CategoryHeader from 'src/components/CategoryHeader'
import { SpotlightNewsSmallField } from 'src/services/articles/articles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { BottomSheet } from '@inter/inter-ui'

import ModalContent from './ModalContent/_index'
import {
  Content,
  Variation,
  Button,
  FilterMobile,
  FilterMobileItem,
  Sticky,
  Collapse,
  Circle,
  ButtonInvestment,
  LinkInvestment,
} from './styles'
import buttonsJson from './assets/data/_buttons'

type buttonsJsonProps = {
  id: number
  name: string
  active: boolean
}

type infoJosnProps = {
  key: string
  value: string
}

type analysisProps = {
  title: string
  description: string
}

const WIDTH_LG = 1024
const WIDTH_MD = 768

type dataProps = {
  codigoPapel: string
  cota: number
  data: string
  holdings: number
}

function orderByDate(array: dataProps[]) {
  return array.sort((a: dataProps, b: dataProps) => {
    const aDate = parseISO(a.data)
    const bDate = parseISO(b.data)
    return isBefore(aDate, bDate) ? 1 : -1
  })
}

function getPercentAToB(minValue: number, maxValue: number) {
  return ((maxValue - minValue) * 100) / maxValue
}

function recomendationColor(text: string) {
  switch (text.toLowerCase()) {
    case 'compra':
      return 'text-success'
    case 'venda':
      return 'text-red--base'
    default:
      return 'text-grayscale--400'
  }
}

function numberColor(number: number) {
  if (number > 0) {
    return '#00AA4F'
  }
  if (number < 0) {
    return '#F56A50'
  }
  return '#6A6C72'
}

interface IStocks {
  id: string
  price: number
  image: {
    url: string
    alt: string
  }
  variation: number
  updatedAt: string
  name: string
  description: string
  deepLink: string
  qrCode: string
  recommendation: string
  upside: string
}

type pageContexProps = {
  pageContext: IStocks
}

const Stocks = ({ pageContext }) => {
  const [stocksData, setStocksData] = useState<dataProps[]>([])
  const [up] = useState(false)
  const [buttons, setButtons] = useState<buttonsJsonProps[]>(buttonsJson)
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState(true)
  const [isMobile, setIsMobile] = useState(true)
  const [more, setMore] = useState(false)
  const [numberOfDays, setNumberOfDays] = useState(5)
  const [datePosition, setDatePosition] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [sendDatalayerEvent] = useDataLayer()

  const theme = useTheme()
  const width = useWidth(300)

  const newsData = quotationData[pageContext.item.id] && quotationData[pageContext.item.id][0]

  const author = authorsJSON.find((columnist) => columnist.id === newsData?.authorId)

  function fillComponentStyle(type: 'articleHeader' | 'articleFooter') {
    return {
      theme,
      type,
    }
  }

  const RenderBlocks = (block) => {
    switch (block.type) {
      case 'text':
        return <Text slug={block.key} title={block.content.title} text={block.content.text} />
      case 'image':
        return <Image imageUrl={block.content.url} imageAlt={block.content.alt} />
      case 'file':
        return (
          <a className="mt-5" href={block.content.file.url}>
            {block.content.file.name}
          </a>
        )
      default:
        return <Text slug={block.key} title={block.content.title} text={block.content.text} />
    }
  }

  const dataColumnist = {
    data: author,
    componentStyle: fillComponentStyle('articleHeader'),
    articleId: '5ab9c083-87c2-4a02-92bd-ae11cdba6d20',
  }

  useLayoutEffect(() => {
    setIsTablet(width < WIDTH_LG)
    setIsMobile(width < WIDTH_MD)
  }, [width])

  const breadcrumb = {
    path: [
      { link: '/', text: 'Home' },
      { link: '/acoes', text: 'Ações' },
      { link: `/${pageContext.item.name.toLowerCase()}`, text: pageContext.item.name },
    ],
  }

  const handleClick = (id: number) => {
    const newButtons = buttons.map((item: buttonsJsonProps) => {
      if (item.id === id) {
        return {
          ...item,
          active: true,
        }
      }
      return {
        ...item,
        active: false,
      }
    })

    setDatePosition(id)

    switch (id) {
      case 1:
        setNumberOfDays(5)
        break
      case 2:
        setNumberOfDays(30)
        break
      case 3:
        setNumberOfDays(252)
        break
      case 4:
        setNumberOfDays(stocksData.length)
        break
      default:
        setNumberOfDays(5)
    }

    setButtons(newButtons)
  }

  useEffect(() => {
    const date = new Date()
    getData(format(sub(date, { years: 5 }), 'yyyy-MM-dd'))
  }, [])

  async function getData(date: string) {
    const response = await axios.get(
      `${CMS_API_QUOTATIONS}?codigoPapel=${pageContext.item.name}&dataInicial=${date}&quantidadeRegistros=500`,
    )
    setStocksData(orderByDate(response.data.tracks))
  }

  const formatedStocksData = stocksData.map((item) => ({
    ...item,
    data: format(parseISO(item.data), numberOfDays > 252 ? 'yyyy' : "dd 'de' LLL'.'", {
      locale: pt,
    }),
  }))
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <PageTitle
            breadcrumb={breadcrumb}
            pageTitle={`${pageContext.item.name} - ${pageContext.item.description}`}
          />
          <div className="col-12 col-lg-8">
            <Content className="mb-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="fs-16 lh-19 fs-md-20 lh-md-25 mb-0 text-grayscale--500">
                      R${convertFloatToCurrencyString(pageContext.item.price)}
                    </h4>
                    <Variation
                      className="fs-12 lh-15 fs-md-14 lh-md-17 mb-0"
                      color={numberColor(pageContext.item.variation)}
                    >
                      <OrangeIcon
                        icon={pageContext.item.variation > 0 ? 'chevron-up' : 'chevron-down'}
                        color={numberColor(pageContext.item.variation)}
                        size="SM"
                        className="mx-2"
                      />
                      {String(pageContext.item.variation).replace('.', ',')}% Hoje
                    </Variation>
                  </div>
                  <p className="fs-10 lh-12 fs-md-12 lh-md-15 mb-0">
                    {format(parseISO(pageContext.item.updatedAt), "dd 'de' MMMM", { locale: pt })},
                    00:00:49 UTC · BRL · BVMF
                  </p>
                </div>

                {isTablet && (
                  <Button
                    active={showBottomSheet}
                    onClick={() => setShowBottomSheet(!showBottomSheet)}
                  >
                    Filtro
                  </Button>
                )}
              </div>

              {!isTablet && (
                <div className="d-flex align-items-center mb-4">
                  {buttons.map((item: buttonsJsonProps) => (
                    <Button onClick={() => handleClick(item.id)} active={item.active}>
                      {item.name}
                    </Button>
                  ))}
                </div>
              )}
              <div>
                <AreaChart
                  data={formatedStocksData.slice(0, numberOfDays).reverse()}
                  datePosition={datePosition}
                />
              </div>
            </Content>

            <div className="row">
              {isTablet && (
                <div className="col-12 col-md-4 col-lg-12 order-md-last mb-4">
                  <Content className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h3 className="fs-14 lh-1 text-grayscale--500 fw-600 mb-0">Recomendação</h3>
                      <span
                        className={`fs-14 lh-16 d-block ${recomendationColor(
                          pageContext.item.recommendation,
                        )} fw-600 mb-0`}
                      >
                        {pageContext.item.recommendation}
                      </span>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between info-row">
                      <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Preço atual</p>
                      <span className="fs-12 lh-14 d-block fw-600 mb-0">
                        R$ {convertFloatToCurrencyString(pageContext.item.price)}
                      </span>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between info-row">
                      <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Preço-alvo</p>
                      <span className="fs-12 lh-14 d-block fw-600 mb-0">
                        R$ {convertFloatToCurrencyString(pageContext.item.priceForecast)}
                      </span>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between info-row">
                      <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Upside</p>
                      <span className="fs-12 lh-14 d-block fw-600 mb-0">
                        {pageContext.item.upside.replace('.', ',')}%
                      </span>
                    </div>
                  </Content>
                  <Content>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h3 className="fs-14 lh-1 text-grayscale--500 fw-600 mb-0">Informações</h3>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between info-row">
                      <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">
                        Último fechamento
                      </p>
                      <span className="fs-12 lh-14 d-block fw-600 mb-0">
                        R$ {convertFloatToCurrencyString(pageContext.item.lastClosingPrice)}
                      </span>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between info-row">
                      <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">
                        <span className="d-md-block d-lg-inline">Variação</span> do dia
                      </p>
                      <span className="fs-12 lh-14 d-block fw-600 mb-0">
                        R$ {convertFloatToCurrencyString(pageContext.item.variationMin)} - R${' '}
                        {convertFloatToCurrencyString(pageContext.item.variationMax)}
                      </span>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between info-row">
                      <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">
                        Porcentagem de Lucro
                      </p>
                      <span className="fs-12 lh-14 d-block fw-600 mb-0">
                        {convertFloatToCurrencyString(
                          String(
                            getPercentAToB(
                              pageContext.item.variationMin,
                              pageContext.item.variationMax,
                            ),
                          ),
                        )}
                        %
                      </span>
                    </div>
                  </Content>
                  {isMobile ? (
                    <LinkInvestment href={pageContext.item.qrCode}>Investir agora</LinkInvestment>
                  ) : (
                    <ButtonInvestment
                      onClick={() => {
                        setOpenModal(true)
                        sendDatalayerEvent({
                          section: '',
                          section_name: '',
                          element_action: 'click button',
                          element_name: 'Investir agora',
                          redirect_url: '',
                        })
                      }}
                    >
                      Investir agora
                    </ButtonInvestment>
                  )}
                </div>
              )}
              <div className="col-12 col-md-8 col-lg-12 mb-4">
                {pageContext.item.contentBlocks.length > 0 &&
                  pageContext.item.contentBlocks.map((item) => RenderBlocks(item))}

                {newsData && (
                  <Collapse active={more}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h3 className="fs-16 lh-20 fs-lg-20 lh-lg-25 fs-xl-24 lh-xl-30 text-grayscale--500 fw-600">
                        Análise completa
                      </h3>
                      <Circle active={more} onClick={() => setMore(!more)}>
                        <OrangeIcon size="SM" color="#ff7a00" icon="chevron-down" />
                      </Circle>
                    </div>
                    <div className="content-line">
                      {newsData.contentBlocks.map((item) => RenderBlocks(item))}
                      <div className="row">
                        <div className="col-12 col-md-8 col-lg-12">
                          <div className="mt-4">
                            <Tags tags={newsData.tags} />
                            <div className="d-lg-flex align-items-center justify-content-between">
                              {dataColumnist && (
                                <Columnists
                                  {...dataColumnist}
                                  componentStyle={fillComponentStyle('articleFooter')}
                                />
                              )}
                              <div className="mt-4">
                                <h4 className="fs-14 lh-17 fw-700  text-grayscale--500">
                                  Compartilhe essa notícia
                                </h4>
                                <FloatingSocialMedia
                                  items={FloatingSocialMediaSharer}
                                  sharer
                                  // pageContext={pageContext}
                                  direction="row"
                                  justifyContent="justify-content-between"
                                />
                              </div>
                            </div>
                            {newsData.relatedNews.length > 0 && (
                              <div className="mt-5">
                                <CategoryHeader category="Notícias Relacionadas" className="mb-3" />
                                <SpotlightNewsSmall
                                  data={newsData.relatedNews.map((item) =>
                                    SpotlightNewsSmallField(item),
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                )}
              </div>
            </div>
          </div>
          {!isTablet && (
            <div className="col-12 col-lg-4 mb-4">
              <Sticky>
                <Content className="mb-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h3 className="fs-14 lh-1 text-grayscale--500 fw-600 mb-0">Recomendação</h3>
                    <span
                      className={`fs-14 lh-16 d-block ${recomendationColor(
                        pageContext.item.recommendation,
                      )} fw-600 mb-0`}
                    >
                      {pageContext.item.recommendation}
                    </span>
                  </div>
                  <div className="d-flex  align-items-center justify-content-between info-row">
                    <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Preço atual</p>
                    <span className="fs-12 lh-14 d-block fw-600 mb-0">
                      R$ {convertFloatToCurrencyString(pageContext.item.price)}
                    </span>
                  </div>
                  <div className="d-flex  align-items-center justify-content-between info-row">
                    <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Preço-alvo</p>
                    <span className="fs-12 lh-14 d-block fw-600 mb-0">
                      R$ {convertFloatToCurrencyString(pageContext.item.priceForecast)}
                    </span>
                  </div>
                  <div className="d-flex  align-items-center justify-content-between info-row">
                    <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Upside</p>
                    <span className="fs-12 lh-14 d-block fw-600 mb-0">
                      {pageContext.item.upside.replace('.', ',')}%
                    </span>
                  </div>
                </Content>
                <Content>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h3 className="fs-14 lh-1 text-grayscale--500 fw-600 mb-0">Informações</h3>
                  </div>
                  <div className="d-flex  align-items-center justify-content-between info-row">
                    <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Último fechamento</p>
                    <span className="fs-12 lh-14 d-block fw-600 mb-0">
                      R$ {convertFloatToCurrencyString(pageContext.item.lastClosingPrice)}
                    </span>
                  </div>
                  <div className="d-flex  align-items-center justify-content-between info-row">
                    <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">Variação do dia</p>
                    <span className="fs-12 lh-14 d-block fw-600 mb-0">
                      R$ {convertFloatToCurrencyString(pageContext.item.variationMin)} - R${' '}
                      {convertFloatToCurrencyString(pageContext.item.variationMax)}
                    </span>
                  </div>
                  <div className="d-flex  align-items-center justify-content-between info-row">
                    <p className="fs-12 lh-14 text-grayscale--500 fw-400 mb-0">
                      Porcentagem de Lucro
                    </p>
                    <span className="fs-12 lh-14 d-block fw-600 mb-0">
                      {convertFloatToCurrencyString(
                        String(
                          getPercentAToB(
                            pageContext.item.variationMin,
                            pageContext.item.variationMax,
                          ),
                        ),
                      )}
                      %
                    </span>
                  </div>
                </Content>
                {isMobile ? (
                  <LinkInvestment href={pageContext.item.qrCode}>Investir agora</LinkInvestment>
                ) : (
                  <ButtonInvestment
                    onClick={() => {
                      setOpenModal(true)
                      sendDatalayerEvent({
                        section: '',
                        section_name: '',
                        element_action: 'click button',
                        element_name: 'Investir agora',
                        redirect_url: '',
                      })
                    }}
                  >
                    Investir agora
                  </ButtonInvestment>
                )}
              </Sticky>
            </div>
          )}
        </div>
      </div>
      {showBottomSheet && (
        <BottomSheet onClose={() => setShowBottomSheet(false)}>
          <FilterMobile>
            <span className="fs-14 lh-17 fw-700 text-grayscale--500">Filtrar por</span>
            {buttons.map((item: buttonsJsonProps) => (
              <FilterMobileItem active={item.active} onClick={() => handleClick(item.id)}>
                <span className="text">{item.name}</span>
                <span className="circle" />
              </FilterMobileItem>
            ))}
            <button
              type="button"
              onClick={() => {
                setShowBottomSheet(false)
                sendDatalayerEvent({
                  section: '',
                  section_name: '',
                  element_action: 'click button',
                  element_name: 'Aplicar filtro',
                  redirect_url: '',
                })
              }}
            >
              Aplicar filtro
            </button>
          </FilterMobile>
        </BottomSheet>
      )}
      <FullScreenModal setOpen={setOpenModal} open={openModal}>
        <ModalContent name={pageContext.item.name}>
          <QRCode size={185} value={pageContext.item.qrCode} />
        </ModalContent>
      </FullScreenModal>
    </Layout>
  )
}

export default Stocks
