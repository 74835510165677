import React, { useState } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { useTheme } from 'styled-components'
import OpenAccount from 'src/components/OpenAccount'

import { Span } from './style'

interface IModalContent {
  name: string
  children: React.ReactNode
}

const ModalContent = ({ name, children }: IModalContent) => {
  const [openAccount, setOpenAccount] = useState(false)
  const theme = useTheme()
  const [sendDatalayerEvent] = useDataLayer()

  return (
    <>
      {openAccount ? (
        <OpenAccount />
      ) : (
        <div>
          <h3 className="fs-24 lh-30 text-grayscale--500 fw-600">
            Escaneie o QR Code, acesse o app e faça sua aplicação na ação{' '}
            <Span theme={theme}>{name}</Span>
          </h3>
          <div className="d-flex justify-content-center">{children}</div>
          <hr />
          <p className="fs-14 lh-17 text-grayscale--400">
            Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado
            para a loja de aplicativos. Aí é só baixar o app do Inter, abrir a sua conta digital e
            criar a sua comunidade.
          </p>
          <h4 className="fs-16 lh-20 text-grayscale--500 fw-600">
            Não tem sua Conta Digital do Inter ainda?{' '}
            <Span
              theme={theme}
              onClick={() => {
                setOpenAccount(true)
                sendDatalayerEvent({
                  section: '',
                  section_name: 'Escaneie o QR Code, acesse o app e faça sua aplicação na ação',
                  element_action: 'click button',
                  element_name: 'Crie a sua conta agora!',
                  redirect_url: '',
                })
              }}
              pointer
            >
              Crie a sua conta agora!
            </Span>
          </h4>
        </div>
      )}
    </>
  )
}

export default ModalContent
