import React from 'react'

import { Figure } from './style'

type ImageBlock = {
  imageUrl: string
  imageAlt: string
}

const ImageContentBlock = ({ imageUrl, imageAlt }: ImageBlock) => (
  <Figure>
    <img src={imageUrl} alt={imageAlt} />
  </Figure>
)

export default ImageContentBlock
