import styled from 'styled-components'

type spanProps = {
  pointer?: boolean
}

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  cursor: ${({ pointer }: spanProps) => (pointer ? 'pointer' : '')};
`
