import React, { useState, useLayoutEffect, useEffect } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Link } from 'gatsby'

// Hooks
import useWidth from 'src/hooks/useWidth'

// Components
import OragneDsIcon from 'src/components/Icon/OrangeDsIcon'

// Styles
import { Card, Player } from './style'

// Types
type imageProps = {
  alt: string
  url: string
}

type SpotlightNewsSmall = {
  title: string
  link: string
  image: imageProps
  video: boolean
  isFromInset?: boolean
  insetCategory?: string
}

type DataProps = {
  data: SpotlightNewsSmall[]
}

const WIDTH_XL = 1200

const SpotlightNewsSmall = ({ data }: DataProps) => {
  const [news, setNews] = useState<SpotlightNewsSmall[]>([])
  const windowWidth = useWidth(200)
  const [isDesktop, setIsDesktop] = useState(false)
  const [sendDatalayerEvent] = useDataLayer()

  function handleIndex(index: number) {
    return index
  }

  useLayoutEffect(() => {
    setIsDesktop(windowWidth > WIDTH_XL)
  }, [windowWidth])

  useEffect(() => {
    const newData = data.filter((item) => item.image && item.image.url && item.image.url !== '')
    setNews(newData)
  }, [])

  return (
    <div className="row">
      {news.slice(0, isDesktop ? 4 : 3).map((item, index) => (
        <div key={handleIndex(index)} className="col-12 col-md-4 col-xl-3">
          <Card>
            <Link
              to={
                !item.isFromInset
                  ? `${item.link}`
                  : `https://www.inset.com.br/${item.insetCategory}/${item.slug}`
              }
              target={item.isFromInset ? '__blank' : ''}
              rel={item.isFromInset ? 'noreferrer' : ''}
              title={item.title}
              onClick={() => {
                sendDatalayerEvent({
                  section: item.title,
                  element_action: 'click banner',
                  element_name: item.title,
                  redirect_url: item.link,
                  section_name: item.link,
                })
              }}
            >
              <div className="row d-flex align-items-center">
                <div className="col-4 col-md-12">
                  <div className="image">
                    <img loading="lazy" src={item.image.url} alt={item.image.alt} />
                    {item.video && (
                      <Player>
                        <OragneDsIcon size="SM" color="#ffffff" icon="play" />
                      </Player>
                    )}
                  </div>
                </div>
                <div className="col-8 col-md-12">
                  <figcaption className="pt-md-4">
                    <h4 className="fs-12 lh-15 fs-md-16 lh-md-20 text-grayscale-500 m-0">
                      {item.title}
                    </h4>
                  </figcaption>
                </div>
              </div>
            </Link>
          </Card>
        </div>
      ))}
    </div>
  )
}

export default SpotlightNewsSmall
