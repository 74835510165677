import styled, { css } from 'styled-components'

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 8px;
        height: 16px;
        border-radius: 100px;
        background: ${theme.colors.primary};
      }
    }

    a:hover {
      text-decoration: none;
      color: #ff7a00;
    }
  `}
`
