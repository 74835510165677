import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Link from 'gatsby-link'

import { TagsStyle } from './style'

type TagsProps = {
  tags: string[]
}

const Tags = ({ tags }: TagsProps) => {
  const [sendDatalayerEvent] = useDataLayer()
  function handleIndex(index: number) {
    return index
  }

  return (
    <TagsStyle>
      <p className="fs-14 fw-400 lh-18 text-grayscale--400 sora">Tags</p>
      <div className="d-flex flex-wrap">
        {tags.map((item, index) => (
          <Link
            to={`/search?q=${item}`}
            key={handleIndex(index)}
            className="mx-1"
            onClick={() => {
              sendDatalayerEvent({
                section: item,
                element_action: 'click button',
                element_name: 'Tags',
                redirect_url: `${process.env.BASE_URL}/search?q=${item}`,
              })
            }}
          >
            <p
              className="fs-12 fw-700 lh-15 text-white bg-grayscale--500 py-1 px-1 rounded"
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </Link>
        ))}
      </div>
    </TagsStyle>
  )
}

export default Tags
