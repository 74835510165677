import React, { useState, useLayoutEffect } from 'react'

// lib chart
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import useWidth from 'src/hooks/useWidth'

import AreaColor from '../components/AreaColor'
import { Container } from './style'

type dataProps = {
  codigoPapel: string
  cota: number
  data: string
  holdings: number
}
interface IChart {
  data: dataProps[]
  datePosition: number
}

const interval = {
  '1': 0,
  '2': 7,
  '3': 30,
  '4': 100,
}

const WIDTH_MD = 768

const Chart = ({ data, datePosition }: IChart) => {
  const [height, setHeight] = useState(103)
  const [width, setWidth] = useState(287)
  const [isMobile, setIsMobile] = useState(true)

  const widthWindow = useWidth(300)

  useLayoutEffect(() => {
    if (widthWindow < 768) {
      setHeight(127)
      setWidth(287)
    } else if (widthWindow >= 768 && widthWindow < 1024) {
      setHeight(287)
      setWidth(656)
    } else if (widthWindow >= 1024 && widthWindow < 1440) {
      setHeight(200)
      setWidth(573)
    } else if (widthWindow >= 1440) {
      setHeight(253)
      setWidth(700)
    }
  }, [widthWindow])

  useLayoutEffect(() => {
    setIsMobile(widthWindow < WIDTH_MD)
  }, [widthWindow])

  return (
    <Container>
      <AreaColor
        topColor={
          data.length > 0 && data[0].cota < data[data.length - 1].cota ? '#00AA4F90' : '#F56A5080'
        }
        bottonColor={
          data.length > 0 && data[0].cota < data[data.length - 1].cota ? '#00AA4F00' : '#F56A5000'
        }
      />

      {isMobile ? (
        <ResponsiveContainer width="100%" height={127}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#F5F6FA" vertical={false} horizontalPoints={[30, 55, 80, 105]} />
            <XAxis dataKey="data" interval={1} />
            <YAxis interval={0} tickMargin={14} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="cota"
              stroke={
                data.length > 0 && data[0].cota < data[data.length - 1].cota ? '#00AA4F' : '#F56A50'
              }
              fill="url(#grad1)"
              stroke-width="3"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <AreaChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#F5F6FA" vertical={false} horizontalPoints={[30, 55, 80, 105]} />
          <XAxis dataKey="data" interval={interval[datePosition]} />
          <YAxis interval={0} tickMargin={14} domain={['auto', 'auto']} />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="cota"
            stroke={
              data.length > 0 && data[0].cota < data[data.length - 1].cota ? '#00AA4F' : '#F56A50'
            }
            fill="url(#grad1)"
            stroke-width="3"
          />
        </AreaChart>
      )}
    </Container>
  )
}

export default Chart
