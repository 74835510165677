import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Components
import OrangeIcon from 'src/components/Icon/OrangeDsIcon'
import { useTheme } from 'styled-components'

// Styles
import { Header } from './style'

// Types
type CategoryHeaderProps = {
  category: string
  categoryLink?: string
  className?: string
}

const CategoryHeader = ({ category, categoryLink, className }: CategoryHeaderProps) => {
  const theme = useTheme()
  const [sendDatalayerEvent] = useDataLayer()

  return (
    <Header className={className}>
      <h4 className="fs-16 lh-20 text-grayscale-500">
        <span className="mr-2" />
        {category}
      </h4>
      {categoryLink ? (
        <a
          className="fs-14 lh-18 fw-700"
          href={categoryLink}
          title={category}
          onClick={() => {
            sendDatalayerEvent({
              section: 'Últimos conteúdos',
              element_action: 'click button',
              element_name: 'Ver tudo',
              redirect_url: categoryLink,
              section_name: category,
            })
          }}
        >
          Ver tudo
          <OrangeIcon size="MD" icon="arrow-right" color={theme.colors.primary} className="ml-2" />
        </a>
      ) : (
        ''
      )}
    </Header>
  )
}

export default CategoryHeader
