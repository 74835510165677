import styled from 'styled-components'

export const Figure = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 90%;
  }
`
