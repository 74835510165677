import React from 'react'

interface IAreaColor {
  topOffset?: string
  topColor: string
  bottonOffset?: string
  bottonColor: string
}

const AreaColor = ({ topOffset, topColor, bottonOffset, bottonColor }: IAreaColor) => (
  <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset={topOffset || '5%'} stopColor={topColor} />
        <stop offset={bottonOffset || '95%'} stopColor={bottonColor} />
      </linearGradient>
    </defs>
  </svg>
)

export default AreaColor
