import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

type VariationProps = {
  color: string
}

type Buttonprops = {
  active: boolean
}

type TabCategoryProps = {
  active?: boolean
}

type moreProps = {
  active: boolean
}

export const Content = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  border: 1.26962px solid #dedfe4;
  border-radius: 20px;

  .info-row {
    padding: 8px 0;

    & + div:not(div:first-child) {
      border-top: 1px solid #f5f6fa;
    }
  }
`
export const Variation = styled.p`
  color: ${(props: VariationProps) => props.color};
`
export const Button = styled.button`
  padding: 8px 16px;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: Buttonprops) => (props.active ? '#ffffff' : '#161616')};
  background: ${(props: Buttonprops) => (props.active ? '#161616' : '#ffffff')};
  border-radius: 20px;
  border: 1px solid ${(props: Buttonprops) => (props.active ? '#161616' : '#dedfe4')};

  & + button {
    margin-left: 8px;
  }
`
export const FilterMobile = styled.div`
  button {
    width: 100%;
    height: 48px;
    background: #ff7a00;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff7a00;
    margin-top: 12px;
    border-radius: 8px;
  }

  .secondary {
    border: 1px solid #ffdab7;
    background: #fff;
    color: #ff7a00;
  }
`
export const FilterMobileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedfe4;

  .title {
    font-size: 12px;
    line-height: 15px;
    font-family: Inter;
    font-weight: normal;
  }

  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px #d6b7bb;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #ff7a00;
      content: '';
      display: ${(props: TabCategoryProps) => (props.active ? 'block' : 'none')};
      position: absolute;
    }
  }
`
export const Sticky = styled.div`
  position: sticky;
  top: 206px;
`
export const Collapse = styled.div`
  @keyframes heigth {
    from {
      max-height: 64px;
    }

    to {
      max-height: 100%;
    }
  }

  width: 100%;
  max-height: ${(props: moreProps) => (props.active ? '100%' : '64px')};
  padding: 16px;
  border: 1px solid #dedfe4;
  border-radius: 8px;
  overflow: hidden;

  ${(props: moreProps) =>
    props.active &&
    `
    animation: heigth 1s linear;
  `}

  @media (min-width: ${breakpoints.lg}) {
    padding: 24px;
    max-height: ${(props: moreProps) => (props.active ? '100%' : '75px')};
  }

  .content-line {
    border-top: 1px dashed #dedfe4;
    margin-top: 16px;
    padding-top: 32px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (min-width: ${breakpoints.xl}) {
      font-size: 18px;
      line-height: 22px;
    }
  }
`

export const Circle = styled.button`
  width: 32px;
  height: 32px;
  background: #fff2e7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  transition: all 0.5s ease-in-out;
  ${(props: moreProps) =>
    props.active &&
    `
    transform: rotate(180deg);
  `}
  &:focus {
    outline: none;
  }
`
export const ButtonInvestment = styled.button`
  background: #ff7a00;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 16px;

  &:focus {
    outline: none;
  }
`
export const LinkInvestment = styled.a`
  background: #ff7a00;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }
`
