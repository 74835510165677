import React, { useRef } from 'react'

type TextProps = {
  slug: string
  title?: string
  text: string
}

const Text = ({ slug, title, text }: TextProps) => {
  const textRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={textRef} id={slug}>
      {title && (
        <h2 className="text-grayscale--500 fw-600 fs-20 lh-25 fs-md-32 lh-md-40 mb-4">{title}</h2>
      )}
      <div
        className="fs-14 fs-md-18 lh-22 lh-md-26 text-grayscale--400"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export default Text
