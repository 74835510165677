import axios from 'axios'
import queryString from 'query-string'

interface IMainEntity {
  question: string
  answer: string
}

interface IStep {
  position: string
  image: string
  name: string
  text: string
}

export const structuredData = (data) => {
  let parsedData

  switch (data.type) {
    case 'faq':
      parsedData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          data.content.map((item: IMainEntity) => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer,
            },
          })),
        ],
      }
      break

    case 'howTo':
      parsedData = {
        '@context': 'https://schema.org',
        '@type': 'HowTo',
        name: data.name,
        description: data.description,
        step: [
          data.content.map((item: IStep) => ({
            '@type': 'HowToStep',
            position: item.position,
            image: item.image,
            name: item.name,
            text: item.text,
          })),
        ],
      }
      break
    default:
  }

  return parsedData
}

export const sendNewsletterFrom = async (name: string, email: string) => {
  try {
    const formatData = {
      thx: 'https://static.bancointer.com.br/marketing-cloud/success.json',
      err: 'https://static.bancointer.com.br/marketing-cloud/error.json',
      MID: '100012736',
      Name: name,
      'Email Address': email,
      '00N1U00000UFXv3': 1,
      lid: 2268,
    }
    const { data } = await axios.post(
      `${process.env.NEWSLETTER_ENDPOINT}`,
      queryString.stringify(formatData),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    if (data.success) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export const convertFloatToCurrencyString = (value: string) =>
  parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
