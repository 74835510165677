export default [
  {
    id: 1,
    name: 'Semana',
    active: true,
  },
  {
    id: 2,
    name: 'Mês',
    active: false,
  },
  {
    id: 3,
    name: '1 ano',
    active: false,
  },
  {
    id: 4,
    name: '5 anos',
    active: false,
  },
]
