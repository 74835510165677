import styled from 'styled-components'
import breakpoints, { device } from 'src/styles/breakpoints'

export const Card = styled.figure`
  border-bottom: 1px solid #dedfe4;
  padding-bottom: 7px;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 24px;
  }

  a {
    text-decoration: none;
    color: #161616;

    &:hover {
      text-decoration: none;
      color: #161616;

      img {
        transform: scale(1.1);
      }
    }

    .image {
      overflow: hidden;
      border-radius: 12px;
      position: relative;

      img {
        width: 100%;
        transition: all 0.3s ease-in-out 0s;
        object-fit: cover;
        height: 72px;
        @media ${device.tablet} {
          height: 160px;
        }
        @media ${device.desktopLG} {
          height: 200px;
        }
      }
    }

    figcaption {
      h4 {
        height: 48px;
        overflow: hidden;

        @media (min-width: ${breakpoints.md}) {
          height: 40px;
        }
      }
    }
  }
`
export const Player = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #16161624;
  border: 1px solid #ffffff24;
  border-radius: 50%;
  position: absolute;
  bottom: 8px;
  left: 8px;
`
