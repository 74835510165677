import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #161616;

  @media (min-width: ${breakpoints.md}) {
    font-size: 14px;
    line-height: 17px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 10px;
    line-height: 12px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 12px;
    line-height: 15px;
  }

  .recharts-layer {
    path {
      stroke-width: 3px;
    }
  }

  .recharts-cartesian-axis {
    line {
      display: none;
    }
  }

  .recharts-wrapper {
    width: 100% !important;
  }
  .recharts-surface {
    max-width: 100%;
  }

  .recharts-yAxis {
    .recharts-cartesian-axis-ticks .recharts-layer {
      &:first-child {
        display: none;
      }
    }
  }
`
